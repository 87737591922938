@font-face {
  font-family: 'GT America, sans-serif';
  src: url(assets/fonts/GT-America-Standard-Regular.woff2) format('woff2'),
    url(assets/fonts/GT-America-Standard-Regular.woff) format('woff');
  font-style: normal;
}
@font-face {
  font-family: 'Roobert, serif';
  src: url(assets/fonts/Roobert-Regular.otf) format('otf');
  font-style: normal;
}
